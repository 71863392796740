.sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid $gray;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box;
}

.sa-icon.sa-success {
    border-color: $green-mid;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sa-icon.sa-success::before,
.sa-icon.sa-success::after {
    content: '';
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: transparent;
    transform: rotate(45deg);
}

.sa-icon.sa-success::before {
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    transform: rotate(-45deg);
    transform-origin: 60px 60px;
}

.sa-icon.sa-success::after {
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    transform: rotate(-45deg);
    transform-origin: 0px 60px;
}

.sa-icon.sa-success .sa-placeholder {
    width: 80px;
    height: 80px;
    border: 4px solid rgba($green-dark, .5);
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
}

.sa-icon.sa-success .sa-fix {
    width: 5px;
    height: 90px;
    background-color: transparent;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.sa-icon.sa-success.animate::after {
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in;
}

.sa-icon.sa-success {
    border-color: transparent\9;
}

.sa-icon.sa-success .sa-line.sa-tip {
    -ms-transform: rotate(45deg) \9;
}

.sa-icon.sa-success .sa-line.sa-long {
    -ms-transform: rotate(-45deg) \9;
}

.animateSuccessTip {
    -webkit-animation: animateSuccessTip 0.75s;
    animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
    -webkit-animation: animateSuccessLong 0.75s;
    animation: animateSuccessLong 0.75s;
}

@-webkit-keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@-webkit-keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessTip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessLong {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

.sa-icon.sa-success .sa-line {
    height: 5px;
    background-color: $green-mid;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
}

.sa-icon.sa-success .sa-line.sa-tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.sa-icon.sa-success .sa-line.sa-long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@-webkit-keyframes rotatePlaceholder {
    0% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
}

@keyframes rotatePlaceholder {
    0% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
        -webkit-transform: rotate(-405deg);
    }
}


/* FAIL ICON */

.sa-error.hide {
    display: none;
}

.sa-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.sa-error svg {
    width: 100px;
    display: block;
    /*margin: 40px auto 0;*/
}

.sa-error .path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}

.sa-error .path.circle {
    -webkit-animation: dash .9s ease-in-out;
    animation: dash .9s ease-in-out;
}


/* QUESTION ICONS TEXT */

.succes-question {
    margin-top: 86px;
    color: $green-mid;
    font-weight: 900;
    text-align: center;
    font-size: 18px;
}

.fail-question {
    color: $cinnabar-red;
    font-weight: 900;
    text-align: center;
    font-size: 18px;
    width: 100px;
}