.lms {
    &-course {
        &-aside {
            @include transitionNormal;
            &-minify {
                @include transitionNormal;
                .lms-aside {
                    &-btn {
                        transform: rotate(180deg);
                    }
                    &-card {
                        display: none;
                    }
                }
            }
        }
    }
    &-aside {
        &-card {
            &:not(last-child) {
                margin-bottom: 1.375em;
            }
            &-content {
                background-color: $gallery-gray;
                .lms-actions-title-level-1 {
                    padding-right: .5em;
                }
            }
            &-title {
                border-bottom: .0625em solid $white;
            }
            &-chapter,
            ul {
                list-style: none;
                padding: 0;
                li,
                a {
                    color: $black;
                    position: relative;
                }
                .lms-material-icons {
                    font-size: 1em;
                }
            }
            &-chapterlist {
                font-size: .8125em;
                li {
                    padding-left: .6em;
                }
                .active {
                    font-weight: 700;
                    &::before {
                        border-color: transparent transparent transparent $black;
                        border-style: solid;
                        border-width: .4em 0 .4em .4em;
                        content: '';
                        font-size: 1rem;
                        left: -1rem;
                        position: absolute;
                        text-indent: .3em;
                        top: .25em;
                        vertical-align: middle;
                    }
                }
            }
        }
        &-btn {
            cursor: pointer;
            display: none;
            position: absolute;
            right: -.625em;
            top: 0;
            @media(min-width: 75em) {
                display: block;
            }
        }
    }
}