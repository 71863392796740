$borderWidth: .1875rem;
$animationTime: 0.8s;
$border-color-default: $gallery-gray;
$border-color-fill: $goldenrod-yellow;
$size: 3rem;
$howManySteps: 100;
// This for loop creates the 	necessary css animation names 
// Due to the split circle of progress-left and progress right, we must use the animations on each side. 
@for $i from 1 through $howManySteps {
    $stepName: ($i*(100 / $howManySteps));
    //animation only the left side if below 50%
    @if $i <=($howManySteps/2) {
        .progress[data-percentage="#{$stepName}"] {
            .progress-right .progress-bar {
                animation: loading-#{$i} $animationTime linear forwards;
            }
            .progress-left .progress-bar {
                animation: 0;
            }
        }
    }
    //animation only the right side if above 50%
    @if $i>($howManySteps/2) {
        .progress[data-percentage="#{$stepName}"] {
            .progress-right .progress-bar {
                animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
            }
            .progress-left .progress-bar {
                animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
            }
        }
    }
}

//animation
@for $i from 1 through ($howManySteps/2) {
    $degrees: (180/($howManySteps/2));
    $degrees: ($degrees*$i);
    @keyframes loading-#{$i} {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate($degrees);
            transform: rotate(#{$degrees}deg);
        }
    }
}

.lms {
    &-progress {
        &-circle {
            background-color: $white;
            border-radius: 100%;
            border: $borderWidth solid $border-color-default;
            display: table;
            height: $size;
            position: absolute;
            right: 1em;
            top: 1em;
            width: $size;
            >span {
                width: 50%;
                height: 100%;
                overflow: hidden;
                position: absolute;
                top: 0;
                z-index: 1;
            }
            .progress-bar {
                background: none;
                border: $borderWidth solid $border-color-fill;
                height: 100%;
                position: absolute;
                top: 0;
                width: 100%;
            }
            .progress-left {
                left: 0;
                .progress-bar {
                    border-bottom-right-radius: ($size/2);
                    border-top-right-radius: ($size/2);
                    border-left: 0;
                    left: 100%;
                    transform-origin: center left;
                }
            }
            .progress-right {
                right: 0;
                .progress-bar {
                    border-bottom-left-radius: ($size/2);
                    border-top-left-radius: ($size/2);
                    border-right: 0;
                    left: -100%;
                    transform-origin: center right;
                }
            }
        }
        &-value {
            color: $prussian-blue;
            display: table-cell;
            font-weight: 500;
            vertical-align: middle;
        }
    }
}