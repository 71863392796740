.lms {
    &-modal {
        &-description {
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            top: 0;
            left: 0;
            display: none;
            z-index: 2;
        }
        &-content {
            background-color: $white;
            width: 250px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 30px 20px;
        }
        &-close-modal {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
        &-text {
            text-align: left;
            font-size: 0.9em;
        }
    }
}