img {
    height: auto;
    max-width: 100%;
}

a {
    color: inherit;
}

/*i.lms-material-icons {
    //cursor: pointer;
}*/