@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
body {
  font-family: Roboto; }

a {
  text-decoration: none; }
  a:hover, a:focus, a:active {
    text-decoration: none; }

:root {
  --breakpoint-sm: 36em;
  --breakpoint-md: 48em;
  --breakpoint-lg: 62em;
  --breakpoint-xl: 75em; }

img {
  height: auto;
  max-width: 100%; }

a {
  color: inherit; }

/*i.lms-material-icons {
    //cursor: pointer;
}*/
.lms-is-open {
  max-height: 100vh;
  opacity: 1;
  transition: opacity .3s ease-out, max-height .4s ease-out; }

.lms-is-close {
  max-height: 0;
  opacity: 0;
  transition: opacity .3s ease-in, max-height .4s ease-in; }

@media (min-width: 88em) {
  .lms-col-xxl {
    flex: 0 0 calc(100%/5);
    max-width: calc(100%/5); } }

@media (min-width: 110em) {
  .lms-col-xxl {
    flex: 0 0 calc(100%/6);
    max-width: ‭calc(100%/6); } }

.lms-header {
  height: 2.5em; }

.lms-container-nav {
  height: calc(100vh - 2.5em);
  top: 2.5em;
  width: 22em; }

.lms-content {
  overflow: hidden;
  width: 100%; }

.lms-menu {
  width: 100%; }

.lms-course-content-expand {
  flex: 0 0 99%;
  max-width: 99%; }

.lms-course-aside-minify {
  flex: 0 0 1em;
  max-width: 1em;
  overflow: hidden; }

.lms-login-bloc {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: auto;
  width: 100%; }
  @media (min-width: 62em) {
    .lms-login-bloc {
      flex-direction: row; } }

.lms-login-form {
  margin: auto;
  max-width: 80%;
  width: 100%; }
  .lms-login-form img {
    display: block;
    margin: 0 auto 2em; }
  .lms-login-form-group {
    margin: 3em auto;
    position: relative; }
    .lms-login-form-group .lms-material-icons {
      position: absolute;
      top: .25em; }
  .lms-login-form-field {
    border-bottom: 0.0625em solid #E9E9F0; }
    .lms-login-form-field input {
      border: none;
      padding-left: 2em; }
  .lms-login-form-check {
    align-items: center;
    display: flex;
    flex-direction: column; }
    @media (min-width: 36em) {
      .lms-login-form-check {
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between; } }
  .lms-login-form-footer {
    text-align: center; }
  @media (min-width: 36em) {
    .lms-login-form {
      margin: 15em auto 5em;
      max-width: 75%; } }
  @media (min-width: 62em) {
    .lms-login-form {
      max-width: 50%;
      padding: 2em; } }

.lms-login-img {
  display: none; }
  @media (min-width: 36em) {
    .lms-login-img {
      background-color: #C2CDCE;
      display: block;
      text-align: center; }
      .lms-login-img img {
        transform: translateY(-3em); } }
  @media (min-width: 62em) {
    .lms-login-img {
      display: flex;
      width: 50%; }
      .lms-login-img img {
        transform: translate(-3em, 0); } }

.lms-login-welcome {
  color: #5B5B5B;
  font-size: 1.125em;
  text-align: center; }

.lms-custom-control-label {
  color: #5B5B5B; }
  .lms-custom-control-label::before {
    background-color: white;
    border: 0.0625em solid #5B5B5B;
    border-radius: 100% !important;
    height: .75em;
    width: .75em; }

.lms-header {
  background-color: #00354F;
  color: white; }
  .lms-header .nav-link {
    color: white; }
  .lms-header input {
    background-color: transparent;
    border: none;
    border-bottom: 0.0625em solid white;
    color: white; }
  .lms-header .material-icons {
    cursor: pointer; }
  .lms-header-avatar-container {
    background-color: white;
    display: flex;
    height: 2em;
    margin: auto;
    width: 2em; }
    @supports (clip-path: circle()) {
      .lms-header-avatar-container {
        clip-path: circle(50% at center center); } }

.lms-notification-number {
  background-color: #F17046;
  border-radius: 100%;
  bottom: 0;
  display: inline-block;
  font-size: .7em;
  height: 1.5em;
  left: 1.5em;
  position: absolute;
  text-align: center;
  width: 1.5em; }

.lms-logo-container {
  background-color: white;
  height: 11.6875em;
  width: 11.6875em; }
  .lms-logo-container a {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 11.6875em; }

.lms-logo-img {
  height: auto;
  max-width: 90%; }

.lms-container {
  padding-left: 2.125em;
  padding-top: .8125em; }
  .lms-container-nav {
    background-color: #eeeeee; }
    .lms-container-nav .lms-logo-container,
    .lms-container-nav .lms-nav-link span {
      transition: .5s ease; }
    .lms-container-nav.minify {
      width: 3.125em; }
      .lms-container-nav.minify .lms-logo-container,
      .lms-container-nav.minify .lms-nav-link span {
        height: 0;
        margin-left: 1em;
        width: 0;
        transition: all .5s ease; }
      .lms-container-nav.minify .lms-menu-btn {
        transform: rotate(180deg); }
    .lms-container-nav-over {
      position: absolute !important;
      z-index: 2; }

.lms-nav {
  font-size: .875em;
  padding-top: 1.5em;
  text-transform: uppercase; }
  .lms-nav-item:hover .lms-nav-link, .lms-nav-item:active .lms-nav-link, .lms-nav-item:focus .lms-nav-link {
    background-color: #2FAEDE;
    color: white;
    transition: background-color .3s; }
    .lms-nav-item:hover .lms-nav-link i, .lms-nav-item:active .lms-nav-link i, .lms-nav-item:focus .lms-nav-link i {
      color: white; }
  .lms-nav-item:hover .expend, .lms-nav-item:active .expend, .lms-nav-item:focus .expend {
    fill: white;
    transition: all ease-in-out .3s; }
  .lms-nav-item.active .lms-nav-link {
    background-color: #2FAEDE;
    color: white;
    transition: background-color .3s; }
    .lms-nav-item.active .lms-nav-link > i {
      color: white; }
  .lms-nav-item.active .expend {
    fill: white; }
  .lms-nav-item .dropdown-menu {
    background-color: transparent !important;
    border: none;
    font-size: .8em;
    padding-left: 1.65em;
    position: static !important;
    transform: none !important;
    width: 100%; }
  .lms-nav-item .dropdown-item {
    background-color: transparent;
    color: black; }
    .lms-nav-item .dropdown-item:hover, .lms-nav-item .dropdown-item:active, .lms-nav-item .dropdown-item:focus {
      text-decoration: underline; }
    .lms-nav-item .dropdown-item.active {
      font-weight: 500; }
      .lms-nav-item .dropdown-item.active::before {
        content: ">";
        display: inline-block;
        margin-right: .5em; }
  .lms-nav-item .expend {
    position: absolute;
    right: .5em;
    top: .8em; }
  .lms-nav-item.show .expend {
    transform: rotate(-180deg); }
  .lms-nav-link {
    background-color: transparent;
    color: black;
    display: block;
    padding: .65em 1em;
    transition: background-color .3s;
    white-space: nowrap; }

.lms-menu {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .lms-menu::-webkit-scrollbar {
    width: 0; }
  .lms-menu-btn {
    cursor: pointer;
    position: absolute;
    right: -.625em;
    top: 0; }

.lms-breadcrumb {
  color: #5B5B5B;
  font-size: .75em;
  margin: 1em 0 2em; }

.lms-banner {
  background-color: #eeeeee;
  margin: 1.75em 0 2.5em 0;
  padding: .5625em 1.25em; }
  .lms-banner-dashboard-container {
    color: #5B5B5B;
    font-weight: 300; }
  .lms-banner-dashboard-title {
    font-size: 1.75em;
    margin: .5em 0; }
    @media (min-width: 62em) {
      .lms-banner-dashboard-title {
        margin: 0; } }
  @media (min-width: 62em) {
    .lms-banner-dashboard-text {
      margin: 0; } }
  .lms-banner-dashboard-firstName {
    color: #00354F;
    font-weight: 400;
    text-transform: capitalize; }
  .lms-banner-dashboard-lastName {
    color: #00354F;
    font-weight: 400;
    text-transform: uppercase; }
  .lms-banner-title {
    color: #eeeeee;
    font-size: 1.5em;
    font-weight: 500;
    line-height: .7; }
  .lms-banner-suivi {
    color: #5B5B5B;
    font-size: 1.125em;
    font-weight: 300;
    line-height: 1; }
  .lms-banner-number {
    color: #00354F;
    font-size: 1.7em;
    font-weight: 700; }
  .lms-banner-module {
    background-color: #eeeeee; }
    .lms-banner-module-img {
      max-height: 8em;
      overflow: hidden; }
      @media (min-width: 62em) {
        .lms-banner-module-img {
          max-height: none; } }
      @media (min-width: 62em) {
        .lms-banner-module-img > img {
          height: 100%;
          object-fit: contain;
          width: 100%; } }
    .lms-banner-module-description-title {
      font-size: .9375em;
      font-weight: 500; }
    .lms-banner-module-description-text {
      font-size: .8125em; }
    .lms-banner-module-day {
      background-color: #2FAEDE;
      color: white; }
    .lms-banner-module-date {
      background-color: #00354F;
      color: white; }
  .lms-banner-avatar-img {
    clip-path: circle(50% at center);
    display: block;
    height: 5.8125em;
    width: 5.8125em; }
  .lms-banner-bilan-container {
    color: #5B5B5B; }
  .lms-banner-bilan-title {
    color: #2FAEDE;
    font-size: 1.25em;
    font-weight: 500;
    margin: .5em 0; }
    @media (min-width: 62em) {
      .lms-banner-bilan-title {
        margin: 0; } }
  .lms-banner-bilan-text {
    font-size: .875em; }
    @media (min-width: 62em) {
      .lms-banner-bilan-text {
        margin: 0; } }
  .lms-banner-bilan-ref {
    font-size: .75em;
    font-style: italic; }
    @media (min-width: 62em) {
      .lms-banner-bilan-ref {
        margin: 0; } }
  .lms-banner-bilan-firstName {
    color: #00354F;
    font-weight: 400;
    text-transform: capitalize; }
  .lms-banner-bilan-lastName {
    color: #00354F;
    font-weight: 400;
    text-transform: uppercase; }

.lms-content {
  padding: 0 2em; }

.lms-title {
  align-items: end !important;
  margin-bottom: 2em;
  margin-top: 2em; }
  .lms-title > hr {
    background-color: #2FAEDE;
    border: none;
    flex: 1 0 auto;
    height: .0625em;
    margin-bottom: 0; }

.lms-subtitle {
  flex: 1 1 auto; }
  .lms-subtitle > hr {
    background-color: #2FAEDE;
    border: none;
    height: .0625em;
    margin-bottom: 0;
    width: 100%; }

.lms-h1 {
  color: #00354F;
  flex: 0 1 auto;
  font-size: 1.25em;
  margin: 0 1em 0 0;
  max-width: 35em; }

.lms-h2 {
  color: #00354F;
  font-size: .9375em;
  margin: 0; }

.lms-subcontent-title {
  background-color: #eeeeee;
  color: #00354F;
  font-size: .9375em;
  font-weight: 400;
  padding: .5em;
  text-align: center; }

.lms-bg-gray {
  background-color: #eeeeee; }

.lms-h-50 {
  height: calc(50% - .5rem); }

.lms-suivi-modules {
  overflow-x: auto;
  overflow-y: hidden; }

.lms-indicators-item {
  align-items: center;
  border-bottom: 0.0625em solid #848282;
  border-left: 0.0625em solid #848282;
  border-right: 0.0625em solid #848282;
  color: #00354F;
  display: flex;
  padding: .25em .5em;
  width: 100%; }
  .lms-indicators-item:not(:first-child) {
    border-left: 0.0625em solid #848282; }
  .lms-indicators-item svg,
  .lms-indicators-item .lms-icon-try {
    margin-right: .2em; }
  @media (min-width: 36em) {
    .lms-indicators-item {
      width: 50%; }
      .lms-indicators-item:nth-child(2), .lms-indicators-item:nth-child(4) {
        border-left: none; }
      .lms-indicators-item:last-child {
        width: 100%; } }
  @media (min-width: 62em) {
    .lms-indicators-item {
      width: auto; }
      .lms-indicators-item:last-child {
        width: auto; }
      .lms-indicators-item:nth-child(3), .lms-indicators-item:nth-child(5) {
        border-left: none; } }

.lms-table .thead-dark th {
  background-color: #00354F;
  border-color: #00354F; }

.lms-course-content {
  transition: all .3s ease 0s; }
  .lms-course-content-expand {
    transition: all .3s ease 0s; }

@media (min-width: 36em) {
  .signature-form {
    margin: 12em auto 5em; } }

@media screen and (max-width: 75em) {
  .signature-form img {
    margin-top: 11em;
    margin-bottom: 0.4em; } }

@media screen and (max-width: 48em) {
  .signature-form img {
    margin-top: 10em;
    margin-bottom: 0.4em; } }

@media screen and (max-width: 36em) {
  .signature-form img {
    margin-top: 7em;
    margin-bottom: 0.4em; } }

.signature-form div .mt-4:nth-of-type(1) p {
  margin: 0; }
  @media screen and (max-width: 36em) {
    .signature-form div .mt-4:nth-of-type(1) p {
      margin-bottom: 0.5em; } }

.signature-is-present {
  display: flex;
  justify-content: center; }
  .signature-is-present button {
    padding: 0.4em 5em 0.4em 5em; }
    @media screen and (max-width: 75em) {
      .signature-is-present button {
        padding: 0.4em 1em 0.4em 1em; } }
    @media screen and (max-width: 36em) {
      .signature-is-present button {
        padding: 0.4em 1.5em 0.4em 1.5em; } }
    .signature-is-present button:hover {
      background-color: #007bff;
      border-color: #007bff; }
  .signature-is-present button:nth-of-type(1) {
    margin-right: 5em; }
    @media screen and (max-width: 75em) {
      .signature-is-present button:nth-of-type(1) {
        margin-right: 1.5em; } }
    @media screen and (max-width: 36em) {
      .signature-is-present button:nth-of-type(1) {
        margin-right: 1.5em; } }

.signature-submit {
  display: flex;
  justify-content: center; }
  @media screen and (max-width: 36em) {
    .signature-submit {
      margin-top: 1.5em !important; } }
  .signature-submit button {
    padding: 0.4em 5em 0.4em 5em; }

form[name=user_signature] .form-group:nth-of-type(3) input, form[name=animateur_signature] .form-group:nth-of-type(3) input {
  width: 68%; }

form[name=user_signature] .form-group:nth-of-type(3) label, form[name=animateur_signature] .form-group:nth-of-type(3) label {
  margin-bottom: 0;
  width: 75%; }

@media screen and (max-width: 36em) {
  form[name=user_signature] .form-group:nth-of-type(3), form[name=animateur_signature] .form-group:nth-of-type(3) {
    display: inline-block !important; }
    form[name=user_signature] .form-group:nth-of-type(3) input, form[name=animateur_signature] .form-group:nth-of-type(3) input {
      width: 100%; }
    form[name=user_signature] .form-group:nth-of-type(3) label, form[name=animateur_signature] .form-group:nth-of-type(3) label {
      width: 100%;
      margin-bottom: 0.5em; } }

.lms-dashboard-title {
  font-size: 1.375em;
  margin: .5em 0 0; }

.lms-dashboard-subtitle {
  font-size: 1.125em;
  font-weight: 300; }

.lms-dashboard-link {
  color: inherit; }

.lms-dashboard-number {
  color: #2FAEDE;
  font-size: 1.6875em;
  font-weight: 700; }

.lms-dashboard-list, .lms-dashboard-note {
  font-size: .8125em;
  font-weight: 300; }

.lms-dashboard-list {
  list-style: none;
  padding: 0 0 0 .3em; }

.lms-dashboard i.lms-material-icons {
  cursor: auto; }

.lms-calendar .fc-toolbar h2 {
  font-size: 1em;
  font-weight: 400;
  margin-top: .5em; }

.lms-calendar .fc-prev-button,
.lms-calendar .fc-next-button,
.lms-calendar .fc-prevYear-button,
.lms-calendar .fc-nextYear-button {
  background-color: #848282;
  border-color: #848282;
  border-radius: 100%;
  font-size: .9375rem; }
  .lms-calendar .fc-prev-button.active, .lms-calendar .fc-prev-button.disabled,
  .lms-calendar .fc-next-button.active,
  .lms-calendar .fc-next-button.disabled,
  .lms-calendar .fc-prevYear-button.active,
  .lms-calendar .fc-prevYear-button.disabled,
  .lms-calendar .fc-nextYear-button.active,
  .lms-calendar .fc-nextYear-button.disabled {
    background-color: #848282;
    border-color: #848282; }

.lms-calendar .fc-today-button,
.lms-calendar .fc-month-button,
.lms-calendar .fc-listWeek-button {
  background-color: #2FAEDE !important;
  border-color: #2FAEDE !important;
  border-radius: 1.5em;
  font-size: .9375rem;
  line-height: 1;
  margin: .5em 0;
  padding: .5rem 1rem;
  width: max-content; }
  .lms-calendar .fc-today-button.active, .lms-calendar .fc-today-button.disabled,
  .lms-calendar .fc-month-button.active,
  .lms-calendar .fc-month-button.disabled,
  .lms-calendar .fc-listWeek-button.active,
  .lms-calendar .fc-listWeek-button.disabled {
    background-color: #2FAEDE !important;
    border-color: #2FAEDE !important; }

.lms-calendar .fc-toolbar .fc-left,
.lms-calendar .fc-toolbar .fc-right,
.lms-calendar .fc-toolbar .fc-center {
  float: none; }

.lms-calendar .fc-toolbar.fc-header-toolbar,
.lms-calendar .fc-toolbar .fc-left,
.lms-calendar .fc-toolbar .fc-right,
.lms-calendar .fc-toolbar .fc-center {
  display: flex; }

.lms-calendar .fc-toolbar.fc-header-toolbar,
.lms-calendar .fc-toolbar .fc-right {
  flex-direction: column; }
  @media (min-width: 36em) {
    .lms-calendar .fc-toolbar.fc-header-toolbar,
    .lms-calendar .fc-toolbar .fc-right {
      flex-direction: row;
      justify-content: space-between; } }
  @media (min-width: 62em) {
    .lms-calendar .fc-toolbar.fc-header-toolbar,
    .lms-calendar .fc-toolbar .fc-right {
      flex-wrap: wrap; } }

.lms-calendar .fc-toolbar .fc-left,
.lms-calendar .fc-toolbar .fc-right,
.lms-calendar .fc-toolbar .fc-center {
  align-items: center;
  justify-content: center;
  margin: .5em 0; }

@media (min-width: 36em) {
  .lms-calendar .fc-toolbar .fc-right,
  .lms-calendar .fc-toolbar .fc-center {
    margin-left: .5em; } }

.lms-calendar .fc-toolbar .fc-center {
  margin-left: 0;
  width: 100%; }

.lms-card {
  background-color: #eeeeee;
  border-color: transparent;
  line-height: 1.25;
  margin: auto; }
  .lms-card-formation {
    margin: 1.25em;
    width: 18.75em; }
    @media (min-width: 62em) {
      .lms-card-formation {
        margin: 0 2.5em 2.2em 0; } }
    .lms-card-formation .lms-card-front {
      background-color: #00354F; }
    .lms-card-formation .lms-card-title {
      color: white; }
    .lms-card-formation .lms-card-img {
      background-color: white; }
    .lms-card-formation .lms-card-body {
      padding-top: 1.25rem; }
    .lms-card-formation .lms-card-day {
      font-size: 1.1875em;
      font-weight: 700; }
    .lms-card-formation .lms-card-date {
      background-color: #eeeeee;
      color: black;
      font-size: .938em; }
  .lms-card-module {
    margin: 0 2.5em 2.2em 0;
    width: 15em; }
    .lms-card-module .lms-card-front {
      background-color: #eeeeee; }
    .lms-card-module .lms-card-title {
      color: #00354F; }
    .lms-card-module .lms-card-header {
      min-height: 4.5rem;
      padding: .5em; }
    .lms-card-module .lms-card-day {
      font-size: .875em; }
    .lms-card-module .lms-card-date {
      background-color: white;
      color: #00354F;
      font-size: .875em; }
    .lms-card-module-info {
      background-color: #95A21C;
      color: white;
      display: inline-block;
      font-size: .875em;
      font-weight: 500;
      height: 1.5em;
      left: 1em;
      line-height: 1;
      padding: .25em;
      position: absolute;
      top: 2em;
      width: 1.5em; }
    .lms-card-module-type {
      background-color: #00354F;
      color: white;
      display: inline-block;
      margin: .5em -.5em 0 0;
      padding: .25em 2em .25em .5em; }
  .lms-card-user .lms-card-front {
    background-color: #eeeeee; }
  .lms-card-user .lms-card-title {
    background-color: #00354F;
    color: white;
    height: auto;
    font-size: 1em;
    font-weight: 700;
    padding: .5em; }
  .lms-card-user .lms-card-img-round img {
    transform: scale(0.8); }
  .lms-card-user .lms-card-infos {
    border-bottom: 0.0625em solid #2FAEDE;
    margin-bottom: 1em; }
  .lms-card-user-label {
    margin-bottom: .5em;
    text-align: left; }
  .lms-card-user-item {
    font-weight: 500;
    text-align: right; }
  .lms-card-tuteur {
    margin: 1.25em;
    max-width: 17.5em; }
    @media (min-width: 62em) {
      .lms-card-tuteur {
        margin: 0 2em 2em 0; } }
    .lms-card-tuteur .lms-card-body {
      padding: .6875em; }
    .lms-card-tuteur .lms-card-img {
      height: 6.875em; }
    .lms-card-tuteur .lms-card-title {
      color: #00354F;
      font-weight: 500; }
    .lms-card-tuteur .lms-card-item-first {
      border-top: 0.0625em solid #00354F; }
    .lms-card-tuteur .lms-card-item:not(.lms-card-item-first) {
      border-top: 0.0625em solid white; }
    .lms-card-tuteur .lms-card-entitled, .lms-card-tuteur .lms-card-result {
      margin: .5rem 0; }
    .lms-card-tuteur .lms-card-entitled {
      color: #5B5B5B;
      font-size: .9375em;
      padding-right: .5em;
      text-align: left; }
    .lms-card-tuteur .lms-card-result {
      color: #2FAEDE;
      font-size: 1.375em;
      font-weight: 500; }
  .lms-card-front, .lms-card-back {
    transition: all .3s ease 0s;
    border: none; }
  .lms-card-front {
    transform: perspective(600px) rotateY(0deg); }
    .lms-card-front-rotate {
      transform: perspective(1600px) rotateY(-180deg); }
  .lms-card-back {
    transform: perspective(1600px) rotateY(180deg);
    background-color: #F9CB6B;
    backface-visibility: hidden;
    font-size: .875em;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
    .lms-card-back-rotate {
      transform: perspective(600px) rotateY(0deg); }
  .lms-card-img {
    align-items: center;
    background-color: transparent;
    display: flex;
    height: 9.6875em;
    overflow: hidden;
    position: relative; }
    .lms-card-img-round {
      align-items: center;
      background-color: transparent;
      display: flex;
      height: 8.5625em;
      margin: auto;
      overflow: hidden;
      width: 8.5625em; }
      .lms-card-img-round img {
        border-radius: 100%; }
      @supports (clip-path: circle()) {
        .lms-card-img-round {
          clip-path: circle(40% at center center); }
          .lms-card-img-round img {
            border-radius: 0; } }
  .lms-card-succeed {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
    width: 100%; }
    .lms-card-succeed img {
      left: 3rem;
      max-width: 30%;
      position: absolute;
      top: 4rem; }
  .lms-card-title {
    font-size: 1.1875em;
    font-weight: 500;
    height: 6em;
    line-height: 1.2; }
    .lms-card-title-gestion {
      background-color: #00354F;
      color: white;
      font-size: 1em;
      font-weight: 500;
      margin: 0;
      padding: .25em 0; }
  .lms-card-header {
    background-color: transparent;
    border: none; }
  .lms-card-body {
    padding-top: 0; }
  .lms-card-footer {
    border: none; }
  .lms-card-day {
    background-color: #2FAEDE;
    color: white; }
  .lms-card-day, .lms-card-date {
    font-weight: 500;
    padding: .2em .5em;
    width: 100%; }
  .lms-card-date {
    background-color: #00354F;
    color: white; }
  .lms-card-rotate.lms-icon-cross {
    font-size: 0;
    padding: 0 0 .5em .5em;
    position: absolute;
    right: .5em;
    top: .5em;
    transform: rotate(0deg); }
  .lms-card-reward {
    margin-right: 1em;
    max-width: 12em; }
    .lms-card-reward .lms-card {
      background-color: white;
      border-color: #848282; }
      .lms-card-reward .lms-card-header {
        background-color: #00354F;
        color: white;
        padding: .8em .5em .8em .25em;
        position: relative;
        text-transform: uppercase;
        font-size: 14px; }
        .lms-card-reward .lms-card-header::before {
          content: "";
          display: inline-block;
          position: absolute; }
      .lms-card-reward .lms-card-body {
        padding: 2.5em .5em .5em; }
      .lms-card-reward .lms-card-footer {
        background-color: transparent;
        color: #2FAEDE;
        padding: .5em; }
  .lms-card-attestation .lms-card-header::before {
    background: url(images/attestation-top.svg) left center no-repeat;
    height: 3.625em;
    position: absolute;
    width: 3.625em; }
  .lms-card-attestation .lms-card-header span {
    margin-left: 4em; }
  .lms-card-certificat .lms-card-header::before {
    background: url(images/certification-top.svg) left center no-repeat;
    height: 3.5625em;
    position: absolute;
    width: 4.6875em; }
  .lms-card-certificat .lms-card-header span {
    margin-left: 5em; }
  .lms-card-location-block {
    display: table;
    width: 100%; }
  .lms-card-location-bt-infos {
    background-color: transparent;
    border: none;
    display: table-cell;
    cursor: pointer; }
    .lms-card-location-bt-infos .lms-material-icons {
      transition: all .3s ease 0s;
      color: #2FAEDE;
      font-size: 3em; }
      .lms-card-location-bt-infos .lms-material-icons:hover, .lms-card-location-bt-infos .lms-material-icons:active, .lms-card-location-bt-infos .lms-material-icons:focus {
        transition: all .3s ease 0s;
        color: #00354F; }
    .lms-card-location-bt-infos span {
      height: 1px;
      left: -10000em;
      position: absolute;
      overflow: hidden;
      top: auto;
      width: 1px; }
    .lms-card-location-bt-infos-close {
      cursor: pointer;
      position: absolute;
      right: .5em;
      text-align: center;
      top: .5em; }
      .lms-card-location-bt-infos-close .lms-material-icons {
        transition: all .3s ease 0s;
        color: white;
        font-weight: 700; }
        .lms-card-location-bt-infos-close .lms-material-icons:hover, .lms-card-location-bt-infos-close .lms-material-icons:active, .lms-card-location-bt-infos-close .lms-material-icons:focus {
          transition: all .3s ease 0s;
          background-color: white;
          border-radius: 100%;
          color: #00354F; }
      .lms-card-location-bt-infos-close span {
        height: 1px;
        left: -10000em;
        position: absolute;
        overflow: hidden;
        top: auto;
        width: 1px; }
  .lms-card-location-content {
    background-color: #00354F;
    color: white;
    height: 0;
    font-size: .875em;
    opacity: 0;
    padding: 2em;
    position: absolute;
    text-align: left;
    z-index: -1; }
    .lms-card-location-content.show {
      transition: all .3s ease 0s;
      bottom: 0;
      display: block;
      height: 100%;
      left: 0;
      opacity: .9;
      z-index: 1; }
  .lms-card-question-link {
    font-size: .875em; }
    .lms-card-question-link span {
      border-bottom: 0.0625em solid #2FAEDE;
      vertical-align: bottom; }
  .lms-card-question-badge {
    width: 1.5em; }

.lms-table th {
  font-size: .8125em;
  font-weight: 400; }

.lms-table td {
  font-size: .9375em; }

.lms-table-row:not(:first-child):not(:nth-child(2)) {
  border-top: 0.0625em solid #848282; }

.lms-table-cell {
  padding: 2.5em 0; }

.lms-button {
  border: 0.125em solid;
  border-radius: 1.5em;
  cursor: pointer;
  display: inline-block;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding: .5rem 1.5rem;
  text-align: center; }
  .lms-button span {
    vertical-align: middle; }
  .lms-button-blue {
    background-color: #2FAEDE;
    border-color: #2FAEDE;
    color: white;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s; }
    .lms-button-blue:hover {
      background-color: white;
      color: #2FAEDE; }
      .lms-button-blue:hover .lms-material-icons {
        color: #2FAEDE; }
    .lms-button-blue .lms-material-icons {
      color: white;
      transition: all ease-in-out 0.3s; }
  .lms-button-orange {
    background-color: #F9CB6B;
    border-color: #F9CB6B;
    color: #00354F;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s; }
    .lms-button-orange:hover {
      background-color: #00354F;
      color: white; }
      .lms-button-orange:hover .lms-material-icons {
        color: #F9CB6B; }
    .lms-button-orange .lms-material-icons {
      color: #00354F;
      transition: all ease-in-out 0.3s; }
  .lms-button-jaffa {
    background-color: #F17046;
    border-color: #F17046;
    color: white;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s; }
    .lms-button-jaffa:hover {
      background-color: white;
      color: #00354F; }
      .lms-button-jaffa:hover .lms-material-icons {
        color: #00354F; }
    .lms-button-jaffa .lms-material-icons {
      color: white;
      transition: all ease-in-out 0.3s; }
  .lms-button-gray {
    background-color: #eeeeee;
    border-color: #eeeeee;
    color: black;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s; }
    .lms-button-gray:hover {
      background-color: black;
      border-color: black;
      color: white; }
      .lms-button-gray:hover .lms-material-icons {
        color: white;
        transition: all ease-in-out 0.3s; }
    .lms-button-gray .lms-material-icons {
      color: black;
      transition: all ease-in-out 0.3s; }
  .lms-button-red {
    background-color: #E34747;
    border-color: #E34747;
    color: white;
    text-transform: uppercase;
    transition: all ease-in-out 0.3s; }
    .lms-button-red:hover {
      background-color: white;
      color: #E34747; }
      .lms-button-red:hover .lms-material-icons {
        color: #E34747;
        transition: all ease-in-out 0.3s; }
    .lms-button-red .lms-material-icons {
      color: white;
      transition: all ease-in-out 0.3s; }
  .lms-button-view {
    background-color: white;
    border-color: #5B5B5B;
    color: black;
    font-weight: 400; }
  .lms-button-download {
    background-color: white;
    border-color: #00354F;
    color: black;
    font-weight: 400;
    white-space: nowrap; }
  .lms-button .material-icons {
    margin: 0 .25em;
    vertical-align: middle; }

.lms-switchToggle input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute; }

.lms-switchToggle label {
  cursor: pointer;
  text-indent: -9999px;
  width: 115px;
  max-width: 115px;
  height: 30px;
  background: #00354F;
  display: block;
  border-radius: 100px;
  position: relative;
  line-height: 21px; }

.lms-switchToggle label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: white;
  border-radius: 90px;
  transition: 0.3s; }

.lms-switchToggle input:checked + label,
.lms-switchToggle input:checked + input + label {
  background: #00354F; }

.lms-switchToggle input + label:before,
.lms-switchToggle input + input + label:before {
  content: 'tuteur';
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  font-size: 12px;
  color: white; }

.lms-switchToggle input:checked + label:before,
.lms-switchToggle input:checked + input + label:before {
  content: 'apprenant';
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  left: 10px;
  width: 86px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: white; }

.lms-switchToggle input:checked + label:after,
.lms-switchToggle input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%); }

.lms-switchToggle label:active:after {
  width: 60px; }

.lms-switchToggle1 input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute; }

.lms-switchToggle1 label {
  cursor: pointer;
  text-indent: -9999px;
  width: 115px;
  max-width: 115px;
  height: 30px;
  background: #00354F;
  display: block;
  border-radius: 100px;
  position: relative;
  line-height: 21px; }

.lms-switchToggle1 label:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 26px;
  height: 26px;
  background: white;
  border-radius: 90px;
  transition: 0.3s; }

.lms-switchToggle1 input:checked + label,
.lms-switchToggle1 input:checked + input + label {
  background: #00354F; }

.lms-switchToggle1 input + label:before,
.lms-switchToggle1 input + input + label:before {
  content: 'Archivées';
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  left: 35px;
  width: 26px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  font-size: 12px;
  color: white; }

.lms-switchToggle1 input:checked + label:before,
.lms-switchToggle1 input:checked + input + label:before {
  content: 'En cours';
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  left: 10px;
  width: 86px;
  height: 26px;
  border-radius: 90px;
  transition: 0.3s;
  text-indent: 0;
  color: white; }

.lms-switchToggle1 input:checked + label:after,
.lms-switchToggle1 input:checked + input + label:after {
  left: calc(100% - 2px);
  transform: translateX(-100%); }

.lms-switchToggle1 label:active:after {
  width: 60px; }

.lms-switchToggleArchived .btn-primary,
.lms-switchToggleArchived .toggle-off {
  background-color: #00354F;
  border: none;
  border-radius: 100px; }
  .lms-switchToggleArchived .btn-primary:hover, .lms-switchToggleArchived .btn-primary:active, .lms-switchToggleArchived .btn-primary:focus,
  .lms-switchToggleArchived .toggle-off:hover,
  .lms-switchToggleArchived .toggle-off:active,
  .lms-switchToggleArchived .toggle-off:focus {
    background-color: #00354F;
    border: none;
    color: white; }

.lms-switchToggleArchived .toggle {
  border: none; }
  .lms-switchToggleArchived .toggle-handle {
    border: none;
    border-radius: 100%;
    height: 2em;
    margin-right: 2.4em;
    margin-top: .2em;
    width: 2em; }
    .lms-switchToggleArchived .toggle-handle.btn:hover, .lms-switchToggleArchived .toggle-handle.btn:active, .lms-switchToggleArchived .toggle-handle.btn:focus {
      background-color: white; }
  .lms-switchToggleArchived .toggle.btn {
    min-width: 10em; }
    .lms-switchToggleArchived .toggle.btn.btn-light {
      border-color: transparent; }
  .lms-switchToggleArchived .toggle-on.btn {
    padding-right: 2.5rem; }
  .lms-switchToggleArchived .toggle-off.btn {
    color: white; }
    .lms-switchToggleArchived .toggle-off.btn:hover, .lms-switchToggleArchived .toggle-off.btn:active, .lms-switchToggleArchived .toggle-off.btn:focus {
      background-color: #00354F;
      color: white; }

.lms-switchToggleArchived .off .toggle-handle {
  margin-left: 2.4em;
  margin-right: 0;
  padding-right: 0; }

.lms-switchToggleArchived .btn-light:hover, .lms-switchToggleArchived .btn-light:active, .lms-switchToggleArchived .btn-light:focus {
  background-color: inherit;
  border-radius: 100px;
  color: white; }

.lms-switchToggleArchived .btn-primary:not(:disabled):not(.disabled):active, .lms-switchToggleArchived .btn-primary:not(:disabled):not(.disabled):focus {
  background-color: #00354F;
  color: white; }

.lms-progress-formation {
  border: 0.0625em solid;
  border-radius: 1.5em;
  cursor: pointer;
  font-size: .75em;
  line-height: 2.5;
  margin: 0 auto;
  max-width: 37.5em; }
  .lms-progress-formation * {
    display: block;
    height: 100%;
    width: 100%; }
  .lms-progress-formation span {
    cursor: text; }

.lms-tab:first-child {
  border-right: 0.0625em solid black; }
  .lms-tab:first-child.show {
    background-color: #2FAEDE;
    border-radius: 1.5em 0 0 1.5em;
    color: white; }

.lms-tab:last-child.show {
  background-color: #F9CB6B;
  border-radius: 0 1.5em 1.5em 0; }

.lms-panel {
  display: none; }
  .lms-panel.active {
    display: flex; }

.lms-course-progress {
  background-color: #eeeeee;
  height: 1em;
  position: relative;
  width: 100%; }
  .lms-course-progress:first-of-type {
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em; }
  .lms-course-progress:last-of-type {
    border-top-right-radius: .5em;
    border-bottom-right-radius: .5em;
    margin-left: .125em; }
  .lms-course-progress-current {
    border-style: solid;
    border-width: 0 .35em .45em .35em;
    border-color: transparent transparent black transparent;
    bottom: -.6em;
    position: absolute; }
  .lms-course-progress img {
    display: block;
    width: .85em;
    position: absolute;
    top: -1.25em; }
  .lms-course-progress-success {
    background-color: #63C4CA;
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em; }
  .lms-course-progress-eval-success {
    background-color: #F17046; }
  .lms-course-progress-pretest.lms-course-progress-success {
    background-color: #F9CB6B;
    height: 1em;
    left: 0;
    position: absolute;
    top: 0;
    width: 1em; }

.progress[data-percentage="1"] .progress-right .progress-bar {
  animation: loading-1 0.8s linear forwards; }

.progress[data-percentage="1"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="2"] .progress-right .progress-bar {
  animation: loading-2 0.8s linear forwards; }

.progress[data-percentage="2"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="3"] .progress-right .progress-bar {
  animation: loading-3 0.8s linear forwards; }

.progress[data-percentage="3"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="4"] .progress-right .progress-bar {
  animation: loading-4 0.8s linear forwards; }

.progress[data-percentage="4"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="5"] .progress-right .progress-bar {
  animation: loading-5 0.8s linear forwards; }

.progress[data-percentage="5"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="6"] .progress-right .progress-bar {
  animation: loading-6 0.8s linear forwards; }

.progress[data-percentage="6"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="7"] .progress-right .progress-bar {
  animation: loading-7 0.8s linear forwards; }

.progress[data-percentage="7"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="8"] .progress-right .progress-bar {
  animation: loading-8 0.8s linear forwards; }

.progress[data-percentage="8"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="9"] .progress-right .progress-bar {
  animation: loading-9 0.8s linear forwards; }

.progress[data-percentage="9"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-10 0.8s linear forwards; }

.progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="11"] .progress-right .progress-bar {
  animation: loading-11 0.8s linear forwards; }

.progress[data-percentage="11"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="12"] .progress-right .progress-bar {
  animation: loading-12 0.8s linear forwards; }

.progress[data-percentage="12"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="13"] .progress-right .progress-bar {
  animation: loading-13 0.8s linear forwards; }

.progress[data-percentage="13"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="14"] .progress-right .progress-bar {
  animation: loading-14 0.8s linear forwards; }

.progress[data-percentage="14"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="15"] .progress-right .progress-bar {
  animation: loading-15 0.8s linear forwards; }

.progress[data-percentage="15"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="16"] .progress-right .progress-bar {
  animation: loading-16 0.8s linear forwards; }

.progress[data-percentage="16"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="17"] .progress-right .progress-bar {
  animation: loading-17 0.8s linear forwards; }

.progress[data-percentage="17"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="18"] .progress-right .progress-bar {
  animation: loading-18 0.8s linear forwards; }

.progress[data-percentage="18"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="19"] .progress-right .progress-bar {
  animation: loading-19 0.8s linear forwards; }

.progress[data-percentage="19"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-20 0.8s linear forwards; }

.progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="21"] .progress-right .progress-bar {
  animation: loading-21 0.8s linear forwards; }

.progress[data-percentage="21"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="22"] .progress-right .progress-bar {
  animation: loading-22 0.8s linear forwards; }

.progress[data-percentage="22"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="23"] .progress-right .progress-bar {
  animation: loading-23 0.8s linear forwards; }

.progress[data-percentage="23"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="24"] .progress-right .progress-bar {
  animation: loading-24 0.8s linear forwards; }

.progress[data-percentage="24"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="25"] .progress-right .progress-bar {
  animation: loading-25 0.8s linear forwards; }

.progress[data-percentage="25"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="26"] .progress-right .progress-bar {
  animation: loading-26 0.8s linear forwards; }

.progress[data-percentage="26"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="27"] .progress-right .progress-bar {
  animation: loading-27 0.8s linear forwards; }

.progress[data-percentage="27"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="28"] .progress-right .progress-bar {
  animation: loading-28 0.8s linear forwards; }

.progress[data-percentage="28"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="29"] .progress-right .progress-bar {
  animation: loading-29 0.8s linear forwards; }

.progress[data-percentage="29"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-30 0.8s linear forwards; }

.progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="31"] .progress-right .progress-bar {
  animation: loading-31 0.8s linear forwards; }

.progress[data-percentage="31"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="32"] .progress-right .progress-bar {
  animation: loading-32 0.8s linear forwards; }

.progress[data-percentage="32"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="33"] .progress-right .progress-bar {
  animation: loading-33 0.8s linear forwards; }

.progress[data-percentage="33"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="34"] .progress-right .progress-bar {
  animation: loading-34 0.8s linear forwards; }

.progress[data-percentage="34"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="35"] .progress-right .progress-bar {
  animation: loading-35 0.8s linear forwards; }

.progress[data-percentage="35"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="36"] .progress-right .progress-bar {
  animation: loading-36 0.8s linear forwards; }

.progress[data-percentage="36"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="37"] .progress-right .progress-bar {
  animation: loading-37 0.8s linear forwards; }

.progress[data-percentage="37"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="38"] .progress-right .progress-bar {
  animation: loading-38 0.8s linear forwards; }

.progress[data-percentage="38"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="39"] .progress-right .progress-bar {
  animation: loading-39 0.8s linear forwards; }

.progress[data-percentage="39"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-40 0.8s linear forwards; }

.progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="41"] .progress-right .progress-bar {
  animation: loading-41 0.8s linear forwards; }

.progress[data-percentage="41"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="42"] .progress-right .progress-bar {
  animation: loading-42 0.8s linear forwards; }

.progress[data-percentage="42"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="43"] .progress-right .progress-bar {
  animation: loading-43 0.8s linear forwards; }

.progress[data-percentage="43"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="44"] .progress-right .progress-bar {
  animation: loading-44 0.8s linear forwards; }

.progress[data-percentage="44"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="45"] .progress-right .progress-bar {
  animation: loading-45 0.8s linear forwards; }

.progress[data-percentage="45"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="46"] .progress-right .progress-bar {
  animation: loading-46 0.8s linear forwards; }

.progress[data-percentage="46"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="47"] .progress-right .progress-bar {
  animation: loading-47 0.8s linear forwards; }

.progress[data-percentage="47"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="48"] .progress-right .progress-bar {
  animation: loading-48 0.8s linear forwards; }

.progress[data-percentage="48"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="49"] .progress-right .progress-bar {
  animation: loading-49 0.8s linear forwards; }

.progress[data-percentage="49"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0; }

.progress[data-percentage="51"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="51"] .progress-left .progress-bar {
  animation: loading-1 0.8s linear forwards 0.8s; }

.progress[data-percentage="52"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="52"] .progress-left .progress-bar {
  animation: loading-2 0.8s linear forwards 0.8s; }

.progress[data-percentage="53"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="53"] .progress-left .progress-bar {
  animation: loading-3 0.8s linear forwards 0.8s; }

.progress[data-percentage="54"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="54"] .progress-left .progress-bar {
  animation: loading-4 0.8s linear forwards 0.8s; }

.progress[data-percentage="55"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="55"] .progress-left .progress-bar {
  animation: loading-5 0.8s linear forwards 0.8s; }

.progress[data-percentage="56"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="56"] .progress-left .progress-bar {
  animation: loading-6 0.8s linear forwards 0.8s; }

.progress[data-percentage="57"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="57"] .progress-left .progress-bar {
  animation: loading-7 0.8s linear forwards 0.8s; }

.progress[data-percentage="58"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="58"] .progress-left .progress-bar {
  animation: loading-8 0.8s linear forwards 0.8s; }

.progress[data-percentage="59"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="59"] .progress-left .progress-bar {
  animation: loading-9 0.8s linear forwards 0.8s; }

.progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-10 0.8s linear forwards 0.8s; }

.progress[data-percentage="61"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="61"] .progress-left .progress-bar {
  animation: loading-11 0.8s linear forwards 0.8s; }

.progress[data-percentage="62"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="62"] .progress-left .progress-bar {
  animation: loading-12 0.8s linear forwards 0.8s; }

.progress[data-percentage="63"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="63"] .progress-left .progress-bar {
  animation: loading-13 0.8s linear forwards 0.8s; }

.progress[data-percentage="64"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="64"] .progress-left .progress-bar {
  animation: loading-14 0.8s linear forwards 0.8s; }

.progress[data-percentage="65"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="65"] .progress-left .progress-bar {
  animation: loading-15 0.8s linear forwards 0.8s; }

.progress[data-percentage="66"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="66"] .progress-left .progress-bar {
  animation: loading-16 0.8s linear forwards 0.8s; }

.progress[data-percentage="67"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="67"] .progress-left .progress-bar {
  animation: loading-17 0.8s linear forwards 0.8s; }

.progress[data-percentage="68"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="68"] .progress-left .progress-bar {
  animation: loading-18 0.8s linear forwards 0.8s; }

.progress[data-percentage="69"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="69"] .progress-left .progress-bar {
  animation: loading-19 0.8s linear forwards 0.8s; }

.progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-20 0.8s linear forwards 0.8s; }

.progress[data-percentage="71"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="71"] .progress-left .progress-bar {
  animation: loading-21 0.8s linear forwards 0.8s; }

.progress[data-percentage="72"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="72"] .progress-left .progress-bar {
  animation: loading-22 0.8s linear forwards 0.8s; }

.progress[data-percentage="73"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="73"] .progress-left .progress-bar {
  animation: loading-23 0.8s linear forwards 0.8s; }

.progress[data-percentage="74"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="74"] .progress-left .progress-bar {
  animation: loading-24 0.8s linear forwards 0.8s; }

.progress[data-percentage="75"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="75"] .progress-left .progress-bar {
  animation: loading-25 0.8s linear forwards 0.8s; }

.progress[data-percentage="76"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="76"] .progress-left .progress-bar {
  animation: loading-26 0.8s linear forwards 0.8s; }

.progress[data-percentage="77"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="77"] .progress-left .progress-bar {
  animation: loading-27 0.8s linear forwards 0.8s; }

.progress[data-percentage="78"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="78"] .progress-left .progress-bar {
  animation: loading-28 0.8s linear forwards 0.8s; }

.progress[data-percentage="79"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="79"] .progress-left .progress-bar {
  animation: loading-29 0.8s linear forwards 0.8s; }

.progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-30 0.8s linear forwards 0.8s; }

.progress[data-percentage="81"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="81"] .progress-left .progress-bar {
  animation: loading-31 0.8s linear forwards 0.8s; }

.progress[data-percentage="82"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="82"] .progress-left .progress-bar {
  animation: loading-32 0.8s linear forwards 0.8s; }

.progress[data-percentage="83"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="83"] .progress-left .progress-bar {
  animation: loading-33 0.8s linear forwards 0.8s; }

.progress[data-percentage="84"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="84"] .progress-left .progress-bar {
  animation: loading-34 0.8s linear forwards 0.8s; }

.progress[data-percentage="85"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="85"] .progress-left .progress-bar {
  animation: loading-35 0.8s linear forwards 0.8s; }

.progress[data-percentage="86"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="86"] .progress-left .progress-bar {
  animation: loading-36 0.8s linear forwards 0.8s; }

.progress[data-percentage="87"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="87"] .progress-left .progress-bar {
  animation: loading-37 0.8s linear forwards 0.8s; }

.progress[data-percentage="88"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="88"] .progress-left .progress-bar {
  animation: loading-38 0.8s linear forwards 0.8s; }

.progress[data-percentage="89"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="89"] .progress-left .progress-bar {
  animation: loading-39 0.8s linear forwards 0.8s; }

.progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-40 0.8s linear forwards 0.8s; }

.progress[data-percentage="91"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="91"] .progress-left .progress-bar {
  animation: loading-41 0.8s linear forwards 0.8s; }

.progress[data-percentage="92"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="92"] .progress-left .progress-bar {
  animation: loading-42 0.8s linear forwards 0.8s; }

.progress[data-percentage="93"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="93"] .progress-left .progress-bar {
  animation: loading-43 0.8s linear forwards 0.8s; }

.progress[data-percentage="94"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="94"] .progress-left .progress-bar {
  animation: loading-44 0.8s linear forwards 0.8s; }

.progress[data-percentage="95"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="95"] .progress-left .progress-bar {
  animation: loading-45 0.8s linear forwards 0.8s; }

.progress[data-percentage="96"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="96"] .progress-left .progress-bar {
  animation: loading-46 0.8s linear forwards 0.8s; }

.progress[data-percentage="97"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="97"] .progress-left .progress-bar {
  animation: loading-47 0.8s linear forwards 0.8s; }

.progress[data-percentage="98"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="98"] .progress-left .progress-bar {
  animation: loading-48 0.8s linear forwards 0.8s; }

.progress[data-percentage="99"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="99"] .progress-left .progress-bar {
  animation: loading-49 0.8s linear forwards 0.8s; }

.progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-50 0.8s linear forwards; }

.progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-50 0.8s linear forwards 0.8s; }

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(3.6);
    transform: rotate(3.6deg); } }

@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(7.2);
    transform: rotate(7.2deg); } }

@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(10.8);
    transform: rotate(10.8deg); } }

@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(14.4);
    transform: rotate(14.4deg); } }

@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(18);
    transform: rotate(18deg); } }

@keyframes loading-6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(21.6);
    transform: rotate(21.6deg); } }

@keyframes loading-7 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(25.2);
    transform: rotate(25.2deg); } }

@keyframes loading-8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(28.8);
    transform: rotate(28.8deg); } }

@keyframes loading-9 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(32.4);
    transform: rotate(32.4deg); } }

@keyframes loading-10 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(36);
    transform: rotate(36deg); } }

@keyframes loading-11 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(39.6);
    transform: rotate(39.6deg); } }

@keyframes loading-12 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(43.2);
    transform: rotate(43.2deg); } }

@keyframes loading-13 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(46.8);
    transform: rotate(46.8deg); } }

@keyframes loading-14 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(50.4);
    transform: rotate(50.4deg); } }

@keyframes loading-15 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(54);
    transform: rotate(54deg); } }

@keyframes loading-16 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(57.6);
    transform: rotate(57.6deg); } }

@keyframes loading-17 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(61.2);
    transform: rotate(61.2deg); } }

@keyframes loading-18 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(64.8);
    transform: rotate(64.8deg); } }

@keyframes loading-19 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(68.4);
    transform: rotate(68.4deg); } }

@keyframes loading-20 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(72);
    transform: rotate(72deg); } }

@keyframes loading-21 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(75.6);
    transform: rotate(75.6deg); } }

@keyframes loading-22 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(79.2);
    transform: rotate(79.2deg); } }

@keyframes loading-23 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(82.8);
    transform: rotate(82.8deg); } }

@keyframes loading-24 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(86.4);
    transform: rotate(86.4deg); } }

@keyframes loading-25 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(90);
    transform: rotate(90deg); } }

@keyframes loading-26 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(93.6);
    transform: rotate(93.6deg); } }

@keyframes loading-27 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(97.2);
    transform: rotate(97.2deg); } }

@keyframes loading-28 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(100.8);
    transform: rotate(100.8deg); } }

@keyframes loading-29 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(104.4);
    transform: rotate(104.4deg); } }

@keyframes loading-30 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(108);
    transform: rotate(108deg); } }

@keyframes loading-31 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(111.6);
    transform: rotate(111.6deg); } }

@keyframes loading-32 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(115.2);
    transform: rotate(115.2deg); } }

@keyframes loading-33 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(118.8);
    transform: rotate(118.8deg); } }

@keyframes loading-34 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(122.4);
    transform: rotate(122.4deg); } }

@keyframes loading-35 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(126);
    transform: rotate(126deg); } }

@keyframes loading-36 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(129.6);
    transform: rotate(129.6deg); } }

@keyframes loading-37 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(133.2);
    transform: rotate(133.2deg); } }

@keyframes loading-38 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(136.8);
    transform: rotate(136.8deg); } }

@keyframes loading-39 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(140.4);
    transform: rotate(140.4deg); } }

@keyframes loading-40 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(144);
    transform: rotate(144deg); } }

@keyframes loading-41 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(147.6);
    transform: rotate(147.6deg); } }

@keyframes loading-42 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(151.2);
    transform: rotate(151.2deg); } }

@keyframes loading-43 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(154.8);
    transform: rotate(154.8deg); } }

@keyframes loading-44 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(158.4);
    transform: rotate(158.4deg); } }

@keyframes loading-45 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(162);
    transform: rotate(162deg); } }

@keyframes loading-46 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(165.6);
    transform: rotate(165.6deg); } }

@keyframes loading-47 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(169.2);
    transform: rotate(169.2deg); } }

@keyframes loading-48 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(172.8);
    transform: rotate(172.8deg); } }

@keyframes loading-49 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(176.4);
    transform: rotate(176.4deg); } }

@keyframes loading-50 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180);
    transform: rotate(180deg); } }

.lms-progress-circle {
  background-color: white;
  border-radius: 100%;
  border: 0.1875rem solid #eeeeee;
  display: table;
  height: 3rem;
  position: absolute;
  right: 1em;
  top: 1em;
  width: 3rem; }
  .lms-progress-circle > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1; }
  .lms-progress-circle .progress-bar {
    background: none;
    border: 0.1875rem solid #F9CB6B;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
  .lms-progress-circle .progress-left {
    left: 0; }
    .lms-progress-circle .progress-left .progress-bar {
      border-bottom-right-radius: 1.5rem;
      border-top-right-radius: 1.5rem;
      border-left: 0;
      left: 100%;
      transform-origin: center left; }
  .lms-progress-circle .progress-right {
    right: 0; }
    .lms-progress-circle .progress-right .progress-bar {
      border-bottom-left-radius: 1.5rem;
      border-top-left-radius: 1.5rem;
      border-right: 0;
      left: -100%;
      transform-origin: center right; }

.lms-progress-value {
  color: #00354F;
  display: table-cell;
  font-weight: 500;
  vertical-align: middle; }

.lms-material-icons {
  color: #00354F;
  font-size: 1.4em;
  vertical-align: text-bottom;
  width: 1.2em; }
  .lms-material-icons-blue {
    color: #2FAEDE; }

.lms-icon-plus {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform: rotate(0deg); }

.lms-icon-cross {
  cursor: pointer;
  fill: #2FAEDE;
  transition: all 0.3s ease-in-out;
  transform: rotate(45deg); }

.lms-icon-try {
  display: inline-block;
  width: .375em;
  height: .9375em;
  border: 0.0625em solid #00354F; }
  .lms-icon-try:not(:first-child) {
    margin-left: .125em; }
  .lms-icon-try.done {
    background-color: #00354F; }

.lms-filter-item {
  border: none;
  border-bottom: 0.0625em solid #00354F;
  font-size: .938em;
  padding: .2em 0; }

ul[class*="lms-accordeon"] {
  list-style: none;
  padding: 0; }

.lms-accordeon-formation {
  margin-bottom: 1em; }
  .lms-accordeon-formation-title {
    background-color: #00354F;
    color: white;
    font-size: .9375rem;
    padding: .6875rem 1rem .6875rem 1.5rem; }
    .lms-accordeon-formation-title svg {
      fill: white; }
    .lms-accordeon-formation-title::after {
      clear: both;
      content: "";
      display: table; }

.lms-accordeon-module-title {
  background-color: #eeeeee;
  color: #00354F;
  font-size: .875em;
  padding: .5rem 1rem .25rem 1.5rem; }

.lms-accordeon-module-note {
  position: relative; }

.lms-accordeon-title-icons {
  cursor: pointer;
  margin-left: 1em;
  vertical-align: middle; }

.lms-accordeon-container-note {
  background-color: #eeeeee;
  font-size: .8125em;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1; }

.lms-audio {
  line-height: 1; }
  .lms-audio-controls {
    align-items: center;
    background-color: #eeeeee;
    border-radius: 1em;
    display: flex;
    justify-content: center;
    padding: .5em;
    width: 15em; }
    .lms-audio-controls button {
      background: transparent;
      background-repeat: repeat;
      background-image: none;
      background-size: auto;
      background-size: contain;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      margin: 0;
      text-indent: -99999px; }
      .lms-audio-controls button[data-state="play"] {
        background: url(../images/outline-play_arrow-24px.svg) center no-repeat; }
      .lms-audio-controls button[data-state="pause"] {
        background: url(../images/outline-pause-24px.svg) center no-repeat; }
      .lms-audio-controls button[data-state="mute"] {
        background: url(../images/outline-volume_off-24px.svg) center no-repeat; }
      .lms-audio-controls button[data-state="unmute"] {
        background: url(../images/outline-volume_mute-24px.svg) center no-repeat; }
      .lms-audio-controls button:hover, .lms-audio-controls button:active, .lms-audio-controls button:focus {
        filter: invert(35%);
        outline: none; }
  .lms-audio-progress {
    margin: 0 .5em; }
    .lms-audio-progress progress {
      vertical-align: bottom; }

.lms-course-aside {
  transition: all .3s ease 0s; }
  .lms-course-aside-minify {
    transition: all .3s ease 0s; }
    .lms-course-aside-minify .lms-aside-btn {
      transform: rotate(180deg); }
    .lms-course-aside-minify .lms-aside-card {
      display: none; }

.lms-aside-card:not(last-child) {
  margin-bottom: 1.375em; }

.lms-aside-card-content {
  background-color: #eeeeee; }
  .lms-aside-card-content .lms-actions-title-level-1 {
    padding-right: .5em; }

.lms-aside-card-title {
  border-bottom: 0.0625em solid white; }

.lms-aside-card-chapter,
.lms-aside-card ul {
  list-style: none;
  padding: 0; }
  .lms-aside-card-chapter li,
  .lms-aside-card-chapter a,
  .lms-aside-card ul li,
  .lms-aside-card ul a {
    color: black;
    position: relative; }
  .lms-aside-card-chapter .lms-material-icons,
  .lms-aside-card ul .lms-material-icons {
    font-size: 1em; }

.lms-aside-card-chapterlist {
  font-size: .8125em; }
  .lms-aside-card-chapterlist li {
    padding-left: .6em; }
  .lms-aside-card-chapterlist .active {
    font-weight: 700; }
    .lms-aside-card-chapterlist .active::before {
      border-color: transparent transparent transparent black;
      border-style: solid;
      border-width: .4em 0 .4em .4em;
      content: '';
      font-size: 1rem;
      left: -1rem;
      position: absolute;
      text-indent: .3em;
      top: .25em;
      vertical-align: middle; }

.lms-aside-btn {
  cursor: pointer;
  display: none;
  position: absolute;
  right: -.625em;
  top: 0; }
  @media (min-width: 75em) {
    .lms-aside-btn {
      display: block; } }

.lms-modal-description {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  display: none;
  z-index: 2; }

.lms-modal-content {
  background-color: white;
  width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 20px; }

.lms-modal-close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer; }

.lms-modal-text {
  text-align: left;
  font-size: 0.9em; }

.lms-footer {
  background-color: #848282;
  color: white;
  min-height: 2.9375em; }
  .lms-footer a {
    color: white; }

.lms-question-type-text {
  border-bottom: 0.0625em solid #5B5B5B;
  color: #00354F;
  font-size: 1.125em;
  font-weight: 600;
  margin-left: 1em;
  text-transform: uppercase;
  width: 100%; }

.lms-question-title {
  margin: 1em 0; }
  .lms-question-title .lms-h3,
  .lms-question-title .lms-h4 {
    font-size: 1.125em; }

.lms-question-notice {
  font-style: italic; }

.lms-question-question ul,
.lms-question-question ol {
  list-style: none;
  padding: 0; }
  .lms-question-question ul li,
  .lms-question-question ol li {
    align-items: baseline;
    display: flex; }
    .lms-question-question ul li label,
    .lms-question-question ol li label {
      margin-left: .5em; }

.lms-course-h2 {
  font-size: .9375em;
  font-weight: 500;
  margin: 0; }

.lms-course-cross {
  background-color: #00354F;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1.5em;
  margin: 0 auto;
  position: relative;
  width: 1.5em; }
  .lms-course-cross.active:before, .lms-course-cross:hover:before {
    transform: translate(-50%, -50%) rotate(270deg); }
  .lms-course-cross.active:after, .lms-course-cross:hover:after {
    transform: translate(-50%, -50%) rotate(270deg); }
  .lms-course-cross::before {
    content: '';
    display: block;
    width: 2px;
    height: 14px;
    background-color: white;
    transition: all ease-in-out 0.3s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .lms-course-cross::after {
    content: '';
    display: block;
    width: 2px;
    height: 14px;
    background-color: white;
    transition: all ease-in-out 0.3s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg); }

@media screen and (max-width: 62em) {
  .lms-content iframe {
    height: 400px; } }

.lms-nav-button {
  background-color: #eeeeee;
  border-radius: 100%;
  display: block;
  height: 2em;
  position: absolute;
  top: 2.9em;
  transition: all ease-in-out 0.3s;
  width: 2em; }
  .lms-nav-button:hover {
    background-color: #bbbbbb; }
    .lms-nav-button:hover#back_button {
      left: 0; }
    .lms-nav-button:hover#next_button {
      right: 0; }
  .lms-nav-button#back_button {
    left: .5em; }
  .lms-nav-button#next_button {
    right: .5em; }
  .lms-nav-button .lms-material-icons {
    margin: .25em; }

.lms-textual-container {
  border: 0.0625em solid #5B5B5B;
  max-height: 0;
  margin: -.8em 0 1em;
  padding: 1em 1em 0;
  opacity: 0; }
  .lms-textual-container.active {
    max-height: none;
    opacity: 1;
    transition: opacity .4s ease-in-out, max-height .3s ease-in-out; }

label.required:after {
  content: ' *';
  color: #E34747; }

label.error {
  font-size: .55rem;
  color: #E34747; }

.spinner {
  z-index: 1051;
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7); }

/*
 *  Usage:
 *
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
 *
 */
.hide {
  display: none; }

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: absolute;
  transform: rotateZ(45deg);
  top: 50%;
  left: 50%; }
  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    transform: scale(1.1); }
    .sk-folding-cube .sk-cube:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      animation: sk-foldCubeAngle 2.4s infinite linear both;
      transform-origin: 100% 100%; }
  .sk-folding-cube .sk-cube2 {
    transform: scale(1.1) rotateZ(90deg); }
  .sk-folding-cube .sk-cube3 {
    transform: scale(1.1) rotateZ(180deg); }
  .sk-folding-cube .sk-cube4 {
    transform: scale(1.1) rotateZ(270deg); }
  .sk-folding-cube .sk-cube2:before {
    animation-delay: 0.3s; }
  .sk-folding-cube .sk-cube3:before {
    animation-delay: 0.6s; }
  .sk-folding-cube .sk-cube4:before {
    animation-delay: 0.9s; }

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0; }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1; }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0; } }

.sa-icon {
  width: 80px;
  height: 80px;
  border: 4px solid #848282;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  margin: 20px auto;
  padding: 0;
  position: relative;
  box-sizing: content-box; }

.sa-icon.sa-success {
  border-color: #95A21C;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.sa-icon.sa-success::before,
.sa-icon.sa-success::after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: transparent;
  transform: rotate(45deg); }

.sa-icon.sa-success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  transform: rotate(-45deg);
  transform-origin: 60px 60px; }

.sa-icon.sa-success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  transform: rotate(-45deg);
  transform-origin: 0px 60px; }

.sa-icon.sa-success .sa-placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(66, 84, 14, 0.5);
  -webkit-border-radius: 40px;
  border-radius: 40px;
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2; }

.sa-icon.sa-success .sa-fix {
  width: 5px;
  height: 90px;
  background-color: transparent;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sa-icon.sa-success.animate::after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in; }

.sa-icon.sa-success {
  border-color: transparent\9; }

.sa-icon.sa-success .sa-line.sa-tip {
  -ms-transform: rotate(45deg) \9; }

.sa-icon.sa-success .sa-line.sa-long {
  -ms-transform: rotate(-45deg) \9; }

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s; }

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s; }

@-webkit-keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

@-webkit-keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px; }
  54% {
    width: 0;
    left: 1px;
    top: 19px; }
  70% {
    width: 50px;
    left: -8px;
    top: 37px; }
  84% {
    width: 17px;
    left: 21px;
    top: 48px; }
  100% {
    width: 25px;
    left: 14px;
    top: 45px; } }

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px; }
  65% {
    width: 0;
    right: 46px;
    top: 54px; }
  84% {
    width: 55px;
    right: 0px;
    top: 35px; }
  100% {
    width: 47px;
    right: 8px;
    top: 38px; } }

.sa-icon.sa-success .sa-line {
  height: 5px;
  background-color: #95A21C;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2; }

.sa-icon.sa-success .sa-line.sa-tip {
  width: 25px;
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.sa-icon.sa-success .sa-line.sa-long {
  width: 47px;
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

@-webkit-keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  5% {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  12% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); }
  100% {
    transform: rotate(-405deg);
    -webkit-transform: rotate(-405deg); } }

/* FAIL ICON */
.sa-error.hide {
  display: none; }

.sa-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.sa-error svg {
  width: 100px;
  display: block;
  /*margin: 40px auto 0;*/ }

.sa-error .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0; }

.sa-error .path.circle {
  -webkit-animation: dash .9s ease-in-out;
  animation: dash .9s ease-in-out; }

/* QUESTION ICONS TEXT */
.succes-question {
  margin-top: 86px;
  color: #95A21C;
  font-weight: 900;
  text-align: center;
  font-size: 18px; }

.fail-question {
  color: #E34747;
  font-weight: 900;
  text-align: center;
  font-size: 18px;
  width: 100px; }

select + .btn-group {
  width: 100%; }

button.multiselect {
  text-align: left;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 53, 79, 0.12); }

button.multiselect:after {
  float: right;
  margin-top: 10px;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent; }

.multiselect-container {
  width: 100%;
  border: 1px solid rgba(0, 53, 79, 0.12);
  max-height: 250px;
  overflow: scroll; }

.multiselect-selected-text {
  color: #5B5B5B;
  font-weight: normal;
  font-size: 12px; }

.multiselect-container .multiselect-search {
  border-bottom-color: rgba(0, 53, 79, 0.12); }

.multiselect-container .multiselect-item .input-group {
  margin: 0; }

.multiselect-container .multiselect-item .input-group .multiselect-clear-filter {
  padding: 10px; }

.multiselect-container li .checkbox {
  padding-left: 20px; }

.multiselect-container li:not(.multiselect-item) {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 53, 79, 0.12); }

.multiselect-container li:not(.multiselect-item):last-of-type {
  border-bottom: none; }

.multiselect-container li {
  position: relative; }

.multiselect-container .checkbox input {
  visibility: hidden; }

.multiselect-container .checkbox {
  color: #5B5B5B; }

.multiselect-container li.active .checkbox::after {
  content: "\2713";
  position: absolute;
  left: 22px;
  top: 15px;
  font-size: 14px; }

.multiselect-container .checkbox::before {
  content: "";
  width: 15px;
  height: 15px;
  border: 1px solid #eeeeee;
  display: inline-block;
  vertical-align: text-bottom; }

#editor {
  position: relative;
  width: 100%;
  height: 300px;
  border: 1px solid rgba(0, 53, 79, 0.12); }

.editor-area {
  display: none; }

.table-responsive input,
.table-responsive select {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #5B5B5B;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #C2CDCE;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; }

.paginate_button {
  border: 0.125em solid;
  border-radius: .25rem;
  cursor: pointer;
  display: inline-block;
  font-size: .9375rem;
  font-weight: 500;
  line-height: 1;
  margin: 0.1rem;
  padding: .2rem 0.5rem;
  text-align: center; }
  .paginate_button.current {
    background-color: #2FAEDE;
    border-color: #2FAEDE;
    color: white;
    text-transform: uppercase; }

.paginate_button .current .previous .next {
  background-color: #2FAEDE;
  border-color: #2FAEDE;
  color: white;
  text-transform: uppercase;
  transition: all ease-in-out 0.3s; }

.lms-text-primary {
  color: #2FAEDE; }

.lms-text-secondary {
  color: #5B5B5B; }

.lms-text-light {
  color: #eeeeee; }

.lms-text-success {
  color: #95A21C; }

.lms-text-danger {
  color: #E34747; }

.lms-text-warning {
  color: #F9CB6B; }

.lms-bg-primary {
  background-color: #2FAEDE; }

.lms-bg-secondary {
  background-color: #5B5B5B; }

.lms-bg-light {
  background-color: #eeeeee; }

.lms-bg-success {
  background-color: #95A21C; }

.lms-bg-danger {
  background-color: #E34747; }

.lms-bg-warning {
  background-color: #F9CB6B; }

.lms-bg-dark {
  background-color: #00354F; }

.lms-alert-primary, .lms-badge-primary {
  background-color: #2FAEDE;
  color: white; }
  .lms-alert-primary .lms-material-icons, .lms-badge-primary .lms-material-icons {
    color: white; }

.lms-alert-secondary, .lms-badge-secondary {
  background-color: #5B5B5B;
  color: white; }
  .lms-alert-secondary .lms-material-icons, .lms-badge-secondary .lms-material-icons {
    color: white; }

.lms-alert-light, .lms-badge-light {
  background-color: #eeeeee;
  color: #00354F; }
  .lms-alert-light .lms-material-icons, .lms-badge-light .lms-material-icons {
    color: #00354F; }

.lms-alert-success, .lms-badge-success {
  background-color: #95A21C;
  color: white; }
  .lms-alert-success .lms-material-icons, .lms-badge-success .lms-material-icons {
    color: white; }

.lms-alert-danger, .lms-badge-danger {
  background-color: #E34747;
  color: white; }
  .lms-alert-danger .lms-material-icons, .lms-badge-danger .lms-material-icons {
    color: white; }

.lms-alert-warning, .lms-badge-warning {
  background-color: #F9CB6B;
  color: #00354F; }
  .lms-alert-warning .lms-material-icons, .lms-badge-warning .lms-material-icons {
    color: #00354F; }

.lms-alert-dark, .lms-badge-dark {
  background-color: #00354F;
  color: white; }
  .lms-alert-dark .lms-material-icons, .lms-badge-dark .lms-material-icons {
    color: white; }

.lms-list-none {
  list-style: none;
  padding: 0; }

.lms-word-break {
  word-break: break-all; }

.lms-overflow-auto {
  overflow: auto; }
