.lms {
    &-content {
        padding: 0 2em;
    }
    &-title {
        align-items: end !important;
        margin-bottom: 2em;
        margin-top: 2em;
        // overflow: hidden;
        >hr {
            background-color: $scooter-blue;
            border: none;
            flex: 1 0 auto;
            height: .0625em;
            margin-bottom: 0;
        }
    }
    &-subtitle {
        // margin: 0 0 0 1em;
        // width: 100%;
        flex: 1 1 auto;
        >hr {
            background-color: $scooter-blue;
            border: none;
            height: .0625em;
            margin-bottom: 0;
            width: 100%;
        }
    }
    &-h1 {
        color: $prussian-blue;
        flex: 0 1 auto;
        font-size: 1.25em;
        margin: 0 1em 0 0;
        max-width: 35em;
    }
    &-h2 {
        color: $prussian-blue;
        font-size: .9375em;
        margin: 0;
    }
    &-subcontent {
        &-title {
            background-color: $gallery-gray;
            color: $prussian-blue;
            font-size: .9375em;
            font-weight: 400;
            padding: .5em;
            text-align: center;
        }
    }
    &-bg {
        &-gray {
            background-color: $gallery-gray;
        }
    }
    &-h {
        &-50 {
            height: calc(50% - .5rem);
        }
    }
    &-suivi {
        &-modules {
            overflow-x: auto;
            overflow-y: hidden;
        }
    }
    &-indicators {
        // width: max-content;
        &-item {
            align-items: center;
            border-bottom: .0625em solid $gray;
            border-left: .0625em solid $gray;
            border-right: .0625em solid $gray;
            color: $prussian-blue;
            display: flex;
            // height: 40%;
            // min-height: 2em;
            padding: .25em .5em;
            width: 100%;
            &:not(:first-child) {
                border-left: .0625em solid $gray;
            }
            svg,
            .lms-icon-try {
                margin-right: .2em;
            }
            @media (min-width: $sm) {
                width: 50%;
                &:nth-child(2),
                &:nth-child(4) {
                    border-left: none;
                }
                &:last-child {
                    width: 100%;
                }
            }
            @media (min-width: $lg) {
                width: auto;
                &:last-child {
                    width: auto;
                }
                &:nth-child(3),
                &:nth-child(5) {
                    border-left: none;
                }
            }
        }
    }
    &-table {
        .thead-dark {
            th {
                background-color: $prussian-blue;
                border-color: $prussian-blue;
            }
        }
    }
    &-course {
        &-content {
            @include transitionNormal;
            &-expand {
                @include transitionNormal;
            }
        }
    }
}

//pages de signature formation présentielles
.signature {
    &-form {
        @media (min-width: $sm) {
            margin: 12em auto 5em;
        }
        img {
            @media screen and (max-width: $xl) {
                margin-top: 11em;
                margin-bottom: 0.4em;
            }
            @media screen and (max-width: $md) {
                margin-top: 10em;
                margin-bottom: 0.4em;
            }
            @media screen and (max-width: $sm) {
                margin-top: 7em;
                margin-bottom: 0.4em;
            }
        }
        div .mt-4:nth-of-type(1) {
            p {
                margin: 0;
                @media screen and (max-width: $sm) {
                    margin-bottom: 0.5em;
                }
            }
        }
    }
    &-is-present {
        display: flex;
        justify-content: center;
        button {
            padding: 0.4em 5em 0.4em 5em;
            @media screen and (max-width: $xl) {
                padding: 0.4em 1em 0.4em 1em;
            }
            @media screen and (max-width: $sm) {
                padding: 0.4em 1.5em 0.4em 1.5em;
            }
            &:hover {
                background-color: #007bff;
                border-color: #007bff;
            }
        }
        button:nth-of-type(1) {
            margin-right: 5em;
            @media screen and (max-width: $xl) {
                margin-right: 1.5em;
            }
            @media screen and (max-width: $sm) {
                margin-right: 1.5em;
            }
        }
    } 
    &-submit {
        display: flex;
        justify-content: center;
        @media screen and (max-width: $sm) {
            margin-top: 1.5em!important;
        }
        button {
            padding: 0.4em 5em 0.4em 5em;
        }
    }
}

form[name=user_signature],  form[name=animateur_signature]{
    .form-group:nth-of-type(3) {
        input {
            width: 68%;
        }
        label {
            margin-bottom: 0;
            width: 75%;
        }
        @media screen and (max-width: $sm) {
            display: inline-block!important;
            input {
                width: 100%;
            }
            label {
                width: 100%;
                margin-bottom: 0.5em;
            }
        }
    }
}
// fin code pour pages de signature
